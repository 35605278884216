import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import { VFC } from 'react'

import SadCat from '../assets/images/sad_cat.jpg'
import { Layout } from '../components/Layout'
import { Spacer } from '../components/Spacer'
import { Text } from '../components/Text'

const NotFoundPage: VFC = () => {
  return (
    <Layout title='페이지를 찾을 수 없어요..'>
      <Wrapper>
        <Image src={SadCat as string} />
        <Spacer y={2} />
        <Text as='h2' size={40} weight={800}>
          Ooooooooops!
        </Text>
        <Text size={24} color='gray1'>
          페이지를 찾을 수 없어요...
        </Text>
        <Spacer y={2} />
        <Button onClick={() => navigate('/')}>
          <Text color='primary2'>마음을 다잡고, 글 목록으로 돌아가기</Text>
        </Button>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Image = styled.img`
  width: 100%;
  max-width: 510px;
`

const Button = styled.button`
  padding: 0.5rem 0.5rem;
  border-radius: 8px;
  cursor: pointer;

  ${({ theme }) => css`
    transition: background-color 0.25s ease;
    background-color: ${theme.color.blue1};
    &:hover {
      background-color: ${theme.color.blue2};
    }
  `}
`

export default NotFoundPage
